import { useApolloClient } from '@apollo/client';
import React, { useEffect } from 'react';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';
import { refreshUserFromSSO } from './auth';

export interface LoginFormContextType {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

export const LoginFormContext = React.createContext<LoginFormContextType | undefined>(undefined);

interface Props {
  locationHref?: string;
}

export const LoginFormContextProvider: React.FC<Props> = ({ children, locationHref }) => {
  const [isVisible, setVisible] = React.useState<boolean>(false);
  const [lastLocationHref, setLastLocationHref] = React.useState<string | undefined>(undefined);

  const show = () => {
    setVisible(true);
  };
  const hide = () => {
    setVisible(false);
  };
  const toggle = () => {
    setVisible(!isVisible);
  };

  const isDesktop = !useIsWindowWidthBelow(767);

  // Automatically open the login form when arriving on website
  useEffect(() => {
    if (isDesktop && !sessionStorage.getItem('sessionIsOpened')) {
      show();
      sessionStorage.setItem('sessionIsOpened', 'true');
    }
  }, [isDesktop]);

  // Automatically close the login form when the user changes page.
  useEffect(() => {
    if (locationHref != undefined && locationHref !== lastLocationHref) {
      hide();
      setLastLocationHref(locationHref);
    }
  }, [locationHref]);

  const apolloClient = useApolloClient();
  const handleTabChange = async () => {
    if (document.visibilityState === 'visible' && (await refreshUserFromSSO())) {
      await apolloClient.resetStore();
    }
  };
  useEffect(() => {
    document.addEventListener('visibilitychange', handleTabChange);
    return () => document.removeEventListener('visibilitychange', handleTabChange);
  }, []);

  return <LoginFormContext.Provider value={{ isVisible, show, hide, toggle }}>{children}</LoginFormContext.Provider>;
};
